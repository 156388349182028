import firebase from 'firebase'
//import firestore from 'firebase/firestore'

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyCdq89BCcjiAMnN05DK6QUBGEn0TE3S__M",
  authDomain: "bestself-prompts.firebaseapp.com",
  databaseURL: "https://bestself-prompts.firebaseio.com",
  projectId: "bestself-prompts",
  storageBucket: "bestself-prompts.appspot.com",
  messagingSenderId: "850903038177",
  appId: "1:850903038177:web:14550cf14bf584ae3b5fa0",
  measurementId: "G-3YD7J5PZQE"
};
// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

export default firebaseApp.firestore()
