<template>
  <div class="py-5">
    <div class="container">
      <stripe-elements
      ref="elementsRef"
      :pk="publishableKey"
      :amount="amount"
      @token="tokenCreated"
      @loading="loading = $event"
    >
    </stripe-elements>
    <button class="btn btn-yellow btn-block" @click="submit">Pay ${{amount / 100}}</button></div>

  </div>
</template>

<script>
import axios from 'axios'
//const stripe = require('stripe')('sk_test_4eC39HqLyjWDarjtT1zdp7dc')
import { StripeElements } from 'vue-stripe-checkout';
export default {
  components: {
    StripeElements
  },
  data: () => ({
    loading: false,
    amount: 1000,
    publishableKey: 'pk_test_F5jL7ahdreCj2WxIgswultlK',
    token: null,
    charge: null
  }),
  methods: {
    submit () {
       this.$refs.elementsRef.submit();
    },
    tokenCreated (token) {
      this.token = token;
      // for additional charge objects go to https://stripe.com/docs/api/charges/object
      this.charge = {
        source: token.card,
        amount: this.amount,
        description: this.description
      }

      console.log(JSON.stringify(this.charge));
      
      this.sendTokenToServer(this.charge);
    },
    sendTokenToServer (charge) {
      axios.post('http://localhost:5000/bestself-prompts/us-central1/CheckoutSession',charge)
      .then(res => {
        console.log(res);
      }).catch(err => {
        console.log(err);
      })
    }

  }
}
</script>
