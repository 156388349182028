import Vue from 'vue'
import VueRouter from 'vue-router'
import Cards from '../views/Cards.vue'
import Home from '../views/Home.vue'
import Confirm from '../views/Confirm.vue'
import Signup from '../views/Signup.vue'
import Signin from '../views/Signin.vue'
import Purchase from '../views/Purchase.vue'
import firebase from 'firebase/app'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/cards',
    name: 'Cards',
    component: Cards,
    props: true,
      beforeEnter: (to,from,next) => {
        next()
        if(to.params.deck_id){
          if(window.localStorage.trial != 'true'){
            next()
          }else{
            firebase.auth().onAuthStateChanged(function(user) {
              if(user){
                next()
              }else{
                next({name:'Signup'})
              }
            })
          }

        }else{
         next({name:'Home'})
       }
      }
  },
  {
    path: '/purchase',
    name: 'Purchase',
    component: Purchase
  },
  {
    path: '/thank-you',
    name: 'Confirm',
    component: Confirm
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/signin',
    name: 'Signin',
    component: Signin
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
