<template>
<div v-if="!product">
  <div id="cardcontainer" class="position-relative cards d-flex flex-column justify-content-center align-items-center">

    <header class="prompts d-flex text-center justify-content-center align-items-end">

      <div v-if="!cards.length"  class="py-5">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <Card v-else v-for="card in randomList(cards)" :key="card.key" :card="card" @panEnd="onPanEnd" />

    </header>
    <nav class="prompt-buttons d-inline-block w-100 text-center py-3">
      <button type="button" class="btn btn-secondary btn-lg nav-link d-inline-block mx-1" @click.prevent="onLikeButton(false)">
        <arrow-left-icon size="1.5x" class="custom-class"></arrow-left-icon><span class="d-none">Dislike</span>
      </button>
      <button type="button" class="btn btn-secondary btn-lg nav-link d-inline-block mx-2" @click.prevent="onLikeButton(true)">
        <arrow-right-icon size="1.5x" class="custom-class"></arrow-right-icon><span class="d-none">Like</span>
      </button>
    </nav>
  </div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="border-top py-4 text-center col-12 col-md-6 col-lg-4">
        <h3 class="h4 mb-3">Inspired by {{deck.name}}.</h3>
        <p>{{deck.subtitle}}</p>
        <a :href="deck.url" class="btn btn-yellow">Get Your {{deck.name}}</a>
        <a href="https://bestself.co/collections/prompt-decks" class="nav-link mt-2"><u>Or, Check Out All Decks</u></a>
      </div>
    </div>
  </div>


  <div id="instructions" class="fixed-top d-flex flex-column justify-content-center" v-if="instructions" @click.prevent="instructions = false">
    <div class="container text-center pb-5">
      <div class="card mx-auto w-100 mb-5" style="max-width: 20rem;">
        <div class="card-body py-5">
          <h5 class="card-title">Instructions</h5>
          <p class="card-text">{{deck.instructions}}</p>
          <button  class="btn btn-light border" @click.prevent="instructions = false">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>


<div v-else class="pt-5">
  <div class="container  text-center">
    <header class="text-center mb-4">
      <h1 class="h4">Want more of these prompts?</h1>
      <p>{{deck.end_card}}</p>
    </header>
    <div class="row mx-2 border-top border-bottom px-2 py-4">
      <div class="col-12 px-0">
        <a :href="deck.url" target="_blank">
          <img class="w-75 bg-shadow rounded  mb-3" style="max-width:180px;" :src="deck.img_url" />
        </a>
      </div>

      <div class="col-12">
        <h2 class="h4">{{deck.name}}</h2>
        <p class="">{{deck.subtitle}}</p>
        <a :href="deck.url" target="_blank" class="btn btn-yellow px-3">Get Your {{deck.name}}</a>
      </div>
    </div>
  </div>
</div>
</template>

<script>
// @ is an alias to /src
import {
  ArrowLeftIcon
} from 'vue-feather-icons'
import {
  ArrowRightIcon
} from 'vue-feather-icons'
import Card from '@/components/cards/Card.vue'
import db from '@/firebase/init'
import firebase from 'firebase/app'

const analytics = firebase.analytics();

export default {
  name: 'Cards',
  components: {
    Card,
    ArrowLeftIcon,
    ArrowRightIcon
  },
  data() {
    return {
      threshhold: 5,
      instructions:true,
      userActive: false,
      swipeStarted: false,
      product: false,
      cards: [],
      categories: [],
      allCards: null,
      cardContainer: null,
      deck: null
    }
  },
  methods: {
    initCards() {
      if (!this.allCards) {
        this.allCards = document.querySelectorAll('.prompt')
      }
      let newCards = document.querySelectorAll('.prompt:not(.removed)')

      for (var i = 0; i < newCards.length; i++) {
        let card = newCards[i]
        card.style.zIndex = this.allCards.length - i
        card.style.transform = 'scale(' + (10 - i) / 10 + ') translateY(-' + 35 * i + 'px)'
        card.style.opacity = (10 - i) / 10
      }

      if (this.swipeStarted) {
        if (newCards.length == 0) {
          if (!this.userActive) {
            window.localStorage.trial = true
            this.$router.push('Signup')
          } else {
            this.product = true
          }
        }

      }

    },
    onPanEnd() {

      if (!this.swipeStarted) this.swipeStarted = true

      this.initCards()
    },
    onLikeButton(love) {

      let cards = document.querySelectorAll('.prompt:not(.removed)')
      let removedcards = document.querySelectorAll('.prompt.removed')
      let moveOutWidth = document.body.clientWidth * 1.5

      if (!cards.length) return false

      if (!this.swipeStarted) this.swipeStarted = true
      if (love) {
        let card = cards[0];

        card.classList.add('removed');

        if (love) {
          card.style.transform = 'translate(' + moveOutWidth + 'px, -100px) rotate(-30deg)'
        } else {
          card.style.transform = 'translate(-' + moveOutWidth + 'px, -100px) rotate(30deg)'
        }
      } else {
        if (!removedcards.length) return false
        let card = removedcards[removedcards.length - 1];
        card.classList.remove('removed');
      }


      this.initCards();
    },
    randomList: function(array){
      let currentIndex = array.length
      let temporaryValue
      let randomIndex
      let myRandomizedList

      // Clone the original array into myRandomizedList (shallow copy of array)
      myRandomizedList = array.slice(0)

      // Randomize elements within the myRandomizedList - the shallow copy of original array
      // While there remain elements to shuffle...
      while (0 !== currentIndex) {

          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex)
          currentIndex -= 1

          // And swap it with the current element.
          temporaryValue = myRandomizedList[currentIndex]
          myRandomizedList[currentIndex] = myRandomizedList[randomIndex]
          myRandomizedList[randomIndex] = temporaryValue
      }

      // Return the new array that has been randomized
      return myRandomizedList
      }
  },
  created() {
    //do something after creating vue instance
    window.scrollTo(0, 0);

    this.deck = {
      id: this.$route.params.deck_id,
      name: this.$route.params.deck_name,
      url: this.$route.params.deck_url,
      subtitle: this.$route.params.deck_subtitle,
      end_card: this.$route.params.deck_end_card,
      img_url: this.$route.params.deck_img_url,
      instructions: this.$route.params.deck_instructions
    }

    analytics.logEvent('view_item', {
      'page_title': this.deck.name
    })


    let self = this

    if(window.localStorage.userSignedIn == 'true'){
      self.userActive = true
      self.threshhold = 15
    }

    db.collection('prompts').where("deck_id", "==", self.deck.id).limit(self.threshhold).get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        let prompt = doc.data()
        self.cards.push({
          prompt: prompt.prompt,
          removed: false
        })
        self.initCards()
      })

    }).then(() => {
      self.initCards()
    })

    /*firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        self.userActive = true
        self.threshhold = 15
      }

      db.collection('prompts').where("deck_id", "==", self.deck.id).limit(self.threshhold).get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            let prompt = doc.data()
            self.cards.push({
              prompt: prompt.prompt,
              removed: false
            })
            self.initCards()
          })

        }).then(() => {
          self.initCards()
        })

    })*/





  },
  mounted() {
    this.cardContainer = document.getElementById('cardcontainer')

    //do something after creating vue instance
    /*this.allCards = document.querySelectorAll('.prompt');

    this.initCards()*/
  }
}
</script>
