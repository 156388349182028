<template>
  <div id="app">
    <Nav />
    <main class="pt-5">
        <router-view/>
        <div class="container text-center">
          <div class="border-top py-4">
              <p class="text-muted">Know someone who might like this?</p>
              <div class="sharethis-inline-share-buttons"></div>
          </div>
        </div>

    </main>
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue'

export default {
  name: 'App',
  components: {
    Nav
  },
  data(){
    return {
      trial : window.localStorage.trial
    }
  },
  created() {
    //do something after creating vue instance
  },
  methods: {
    trialEnded() {
      if(this.trial){
        this.$router.push('Signup')
      }
    }
  },
  mounted() {
    //do something after updating vue instance

  }

}
</script>
