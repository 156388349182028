<template>
<div class="signup pt-5 pb-3">

  <div class="container">
    <header class="text-center">
      <h1 class="h3 mb-1">Like what you see?</h1>
      <p class="lead px-3">Sign in and unlock all free prompts.</p>
    </header>
    <ul class="nav nav-tabs px-2 border-0">
      <li class="nav-item col text-center">
        <a class="nav-link " href="/signup">Create Account</a>
      </li>
      <li class="nav-item col text-center">
        <a class="nav-link active btn-light" href="/signin">Sign In</a>
      </li>
    </ul>

    <form @submit.prevent="loginUser" class="py-4 px-4 px-md-5 bg-white rounded">


      <div class="mb-3">
        <label for="email">Email</label>
        <input type="email" class="form-control " :class="{ 'border-danger': error.email }" id="email" aria-describedby="basic-addon3" placeholder="email" v-model="user.email">
        <p class="text-danger small mt-1" v-if="error.email">Fill in your Email address</p>
      </div>

      <div class="mb-4">
        <button type="submit" class="btn btn-yellow w-100 mb-3">Sign In</button>
        <p class="text-danger small mt-1 text-center" v-if="signuperror">{{ signuperror }}</p>
        <p class="small text-secondary text-center">Don't have an account yet? <a href="/signup"><u>Create an account</u></a></p>
      </div>

    </form>
  </div>
</div>
</template>

<script>

import db from '@/firebase/init'
//import firebase from 'firebase/app'

export default {
  name: 'Signup',
  data() {
    return {
      signuperror:null,
      error: {
        email: false
      },
      user : {
        email: null,
        password: null
      },
      errors:false
    }
  },
  methods: {
    loginUser(){

      if(!this.user.email){
        this.error.email = true;
        this.errors = true;
      }else{
        this.error.email = false;
        this.errors = false;
      }

      if(!this.errors){
        console.log('No errors');

        let ref = db.collection('users').doc(this.user.email)
        ref.get().then(doc => {
          if (!doc.exists) {
            console.log('Exists');
            this.error.email = 'This email does not exists'

          }else{
            window.localStorage.userSignedIn = true
            window.localStorage.userEmail = doc.id
            window.localStorage.trial = false
            console.log(window.localStorage);
            this.$router.push({name:`Home`})
          }

        })

        /*FIREBASE SIGNIN */
         /*firebase.auth().signInWithEmailAndPassword(this.user.email, this.user.password)
         .then(cred =>{
           console.log(cred.user);
           console.log(this.user);

         }).then(()=> {
           this.$router.push({name:`Home`})
         })
         .catch(err=>{
           console.log(err);
           this.signuperror = err.message
         })*/
      }
    }
  }
}
</script>
