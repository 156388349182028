<template>
<div class="signup  pt-5 pb-3">
  <div class="container">
    <header class="text-center mb-4">
      <h1 class="h3 mb-1">Like what you see?</h1>
      <p class="lead px-3">Create an account and unlock all free prompts.</p>
    </header>

    <ul class="nav nav-tabs px-2 border-0">
      <li class="nav-item col text-center">
        <a class="nav-link btn active btn-light px-0" href="signup">Create Account</a>
      </li>
      <li class="nav-item col text-center">
        <a class="nav-link " href="signin">Sign In</a>
      </li>
    </ul>
    <form @submit.prevent="registerUser" class="py-4 px-4 px-md-5 bg-white rounded">
      <div class="mb-2">
        <label for="first-name">First name</label>
        <input type="text" class="form-control" placeholder="First name" :class="{ 'border-danger': error.firstName }" v-model="user.firstName">
        <p class="text-danger small mt-1" v-if="error.firstName">Fill in your first name</p>
      </div>


      <div class="mb-3">
        <label for="email">Email</label>
        <input type="email" class="form-control " :class="{ 'border-danger': error.email }" id="email" aria-describedby="basic-addon3" placeholder="email" v-model="user.email">
        <p class="text-danger small mt-1" v-if="error.email">{{error.email}}</p>
      </div>


      <div class="mb-4">
        <button type="submit" class="btn btn-yellow w-100 mb-3">Sign up</button>
        <p class="text-danger small mt-1 text-center" v-if="signuperror">{{ signuperror }}</p>
        <p class="small text-secondary text-center">By continuing, you're confirming that you've read and agree to our <a href="https://bestself.co/pages/privacy-policy" target="_blank" class="text-muted">Privacy Policy</a></p>
      </div>

    </form>
  </div>
</div>
</template>

<script>
import db from '@/firebase/init'
import firebase from 'firebase/app'

const analytics = firebase.analytics();

export default {
  name: 'Signup',
  data() {
    return {
      signuperror: null,
      error: {
        firstName: false,
        email: false,
      },
      errors: false,
      user: {
        firstName: null,
        email: null
      }
    }
  },
  methods: {
    registerUser() {
      if (!this.user.firstName) {
        this.error.firstName = true;
        this.errors = true;
      } else {
        this.error.firstName = false;
        this.errors = false;
      }



      if (!this.user.email) {
        this.error.email = true;
        this.errors = true;
      } else {
        this.error.email = false;
        this.errors = false;
      }

      if (!this.errors) {
        let ref = db.collection('users').doc(this.user.email)
        ref.get().then(doc => {
          if (doc.exists) {
            console.log('Exists');
            this.error.email = 'This email already exists'
          } else {

            this.error.email = null
            ref.set({
              first_name: this.user.firstName,
              email:this.user.email,
            }).then(()=>{

              analytics.logEvent('sign_up', {
                email: this.user.email,
                first_name: this.user.firstName
              });

              window.localStorage.trial = false
              window.localStorage.userSignedIn = true
              window.localStorage.userEmail = this.user.email
              this.$router.push({
                name: `Confirm`
              })

            })


            /* FIREBASE SIGN IN */
            /*firebase.auth().createUserWithEmailAndPassword(this.user.email, this.user.password)
              .then(cred => {
                ref.set({
                  first_name: this.user.firstName,
                  last_name: this.user.lastName,
                  email:this.user.email,
                  user_id: cred.user.uid
                })
                analytics.logEvent('sign_up', {
                  email: this.user.email,
                  first_name: this.user.firstName,
                  last_name: this.user.lastName
                });
              }).then(()=>{
                window.localStorage.trial = 'false'
                this.$router.push({
                  name: `Confirm`
                })
              })
              .catch(err => {
                console.log(err);
                this.signuperror = err.message
              }) */
          }
        })
      }
    }
  }
}
</script>
