<template lang="html">
  <div class="prompt border position-absolute d-inline-flex flex-column  text-center align-items-center px-4 py-3 bg-white" v-hammer:pan="onPan" v-hammer:panend="onPanEnd">

      <h2 class="h4 w-100 d-inline-flex flex-fill text-center justify-content-center align-items-center">{{ this.card.prompt }}</h2>
  </div>
</template>

<script>
import Vue from 'vue'
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

export default {
  name: 'Card',
  props: ['card'],
  data() {
    return {
      el: this.$el
    }
  },
  methods: {
    onPan(event) {

      this.el.classList.add('moving')

      if (event.deltaX === 0) return
      if (event.center.x === 0 && event.center.y === 0) return

      var xMulti = event.deltaX * 0.03
      var yMulti = event.deltaY / 80
      var rotate = xMulti * yMulti

      this.el.style.transform = 'translate(' + event.deltaX + 'px, ' + event.deltaY + 'px) rotate(' + rotate + 'deg)'
    },
    onPanEnd(event){
      this.el.classList.remove('moving')

      var moveOutWidth = document.body.clientWidth
      var keep = Math.abs(event.deltaX) < 80 || Math.abs(event.velocityX) < 0.5

      this.el.classList.toggle('removed', !keep)

      if (keep) {
        this.el.style.transform = ''
      } else {
        var endX = Math.max(Math.abs(event.velocityX) * moveOutWidth, moveOutWidth)
        var toX = event.deltaX > 0 ? endX : -endX
        var endY = Math.abs(event.velocityY) * moveOutWidth
        var toY = event.deltaY > 0 ? endY : -endY
        var xMulti = event.deltaX * 0.03
        var yMulti = event.deltaY / 80
        var rotate = xMulti * yMulti

        this.el.style.transform = 'translate(' + toX + 'px, ' + (toY + event.deltaY) + 'px) rotate(' + rotate + 'deg)'
      }
      this.$emit('panEnd', true)
    }
  },
  mounted() {
    this.el = this.$el
  
  }
}
</script>
