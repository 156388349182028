<template lang="html">
  <div id="nav" class="navbar bg-white py-3 fixed-top">
    <div class="container">
      <router-link :to="{name: 'Home'}"><box-icon size="1.5x" class="custom-class"></box-icon></router-link>
      <router-link :to="{name: 'Home'}"><img src="/images/assets/logo-bs-navy.svg" style="max-width:120px;" class="w-100"/><span class="d-none">BestSelf Co</span></router-link>
      <router-link :to="{name: 'Signup'}"><user-icon size="1.5x" class="custom-class"></user-icon></router-link>
    </div>
  </div>
</template>

<script>
import { UserIcon } from 'vue-feather-icons'
import { BoxIcon } from 'vue-feather-icons'

export default {
  name:'Nav',
  components: {
    UserIcon,
    BoxIcon
  }
}
</script>

<style lang="css" scoped>
</style>
