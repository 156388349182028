<template>
  <div class="home">
    <div class="pt-5">
      <div class="container text-center">
        <header class="row justify-content-center">
          <div class="col-12 col-lg-7 px-3">
            <h1 class="h3">Let’s Get Talking</h1>
            <p class="px-3">A fun tool to help you build connections whether you’re “sheltering in place” or having a face-to-face!</p>
          </div>

        </header>
        <div class="row justify-content-center py-3">
          <div v-if="!decks.length"  class="py-5">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div v-else v-for="deck in decks" :key="deck.key" class="col-12 col-md-6 col-lg-4 decks mb-3">
              <router-link :to="{name: 'Cards', params:{ deck_id: deck.deck_id, deck_name: deck.name, deck_url: deck.url, deck_img_url:deck.img_url, deck_end_card: deck.end_card , deck_subtitle: deck.subtitle, deck_instructions:deck.instructions }}" class="bg-transparant card text-left text-md-center border-0 deck d-flex text-decoration-none" >
                <div class="row px-3">
                  <div class="col-4 col-md-12  my-4">
                    <div class="icon-wrapper rounded mt-md-4 mx-auto py-3 text-center" :style="{backgroundColor: deck.color, maxWidth: 110+'px'}">
                          <img :src="`/images/assets/icons/${deck.name}.png`" class="w-75 d-inline-block" style="max-width: 80px;"/>
                    </div>

                  </div>
                  <div class="col-8 col-md-12 card-body pr-3 pl-2">
                      <h2 class="h5 mb-0">{{deck.title}}</h2>
                      <p class="text-muted mb-2">Inspired by {{ deck.name}}</p>
                      <p class="px-md-3 px-lg-4 px-xl-5">{{deck.text}}</p>
                  </div>
                </div>
              </router-link>
          </div>
        </div>
        <div>
          <div class="border-top py-4 ">
              <h3 class="h4 mb-3">Inspired by the BestSelf Co. Discovery Decks.</h3>
              <a href="https://bestself.co/collections/prompt-decks" class="btn btn-outline-secondary">Check them out</a>
          </div>
        </div>

      </div>
    </div>


  </div>
</template>

<script>
import db from '@/firebase/init'

export default {
  name:'Home',
  data(){
    return {
      decks : []
    }
  },
  created() {
    //do something after creating vue instance
    db.collection('decks').get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          let deck = doc.data()
          this.decks.push({
            deck_id: doc.id,
            name: deck.name,
            color: deck.color,
            subtitle: deck.subtitle,
            url: deck.shop_url,
            img_url:deck.img_url,
            end_card: deck.end_card,
            title: deck.title,
            text: deck.text,
            instructions:deck.instructions
          })
        })

      })
  }
}
</script>
